import axios from 'axios';
import { PageHeader } from 'components/pageHeader/pageHeader';
import { RentDeviceCategoryCard } from 'components/rentDeviceCategoryCard/rentDeviceCategoryCard';
import { FC, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeVariant } from 'types';
import {
  AncestorNode,
  RentDeviceCategory,
  UmbracoRentDeviceCategory,
} from 'types/rentDevice';
import { Container, Box, Text } from 'ui/core';
import Loader from 'ui/core/loader/loader';
import { getTheme } from 'ui/theme/theme';

export const RentDevicesCategoriesApp: FC<{
  umbracoCategories: UmbracoRentDeviceCategory[];
  site: ThemeVariant;
  title?: string;
  ancestors: AncestorNode[];
}> = ({ site, umbracoCategories, title, ancestors }) => {
  const theme = getTheme(site);
  const [categories, setCategories] = useState<(RentDeviceCategory | null)[]>(
    []
  );
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true);
  const fetchCategories = () => {
    setCategoriesLoading(true);
    axios
      .get<RentDeviceCategory[]>('/webapi/RentDevices/categories')
      .then((res) => {
        const cats = !res?.data ? [] : res.data;
        const categoriesWithImage = umbracoCategories
          .map((cat) => {
            const category = cats.find((c) => c.Id.toString() === cat.Id.toString());
            if (!category) return null;
            category.Image = cat.ImageUrl;
            category.Name = cat.Title;
            return category;
          })
          .filter((c) => c !== null);
          
        setCategories(categoriesWithImage);
        setCategoriesLoading(false);
      })
      .catch((e) => setCategoriesLoading(false));
  };

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <PageHeader
          title={title || 'Áhaldaleiga'}
          ancestors={ancestors}
        />
        <Box width="full" display="flex" flexWrap="wrap" my={4}>
          {categoriesLoading ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Text
                variant="textIntro"
                fontWeight="medium"
                mb={4}
              >
                Sæki flokka...
              </Text>
              <Loader />
            </Box>
          ) : (
            categories &&
            categories.length > 0 &&
            categories.map(
              (cat) =>
                cat && (
                  <RentDeviceCategoryCard
                    key={cat.Id}
                    {...cat}
                  />
                )
            )
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};
