import { FC } from 'react';
import { RentDeviceCategory } from 'types/rentDevice';
import { Box, Text, Icon as IconComponent, Figure, ImageBox } from 'ui/core';
import { IconWrapper, StyledCard } from './rentDeviceCategoryCard.styles';

export const RentDeviceCategoryCard: FC<RentDeviceCategory> = ({
  Id,
  Name,
  Image,
}) => (
  <Box
    height="full"
    width={['full', 'full', '1/2', '4/12', '3/12']}
    my={4}
    px={2}
  >
    <Box height="full" width="full" border="light">
      <StyledCard
        href={
          window
            ? window.location.href + '/flokkur?id=' + Id
            : '/upplysingar/thjonusta/ahaldaleiga/flokkur?id=' + Id
        }
      >
        {Image ? (
          <ImageBox imgSrc={`${Image}?width=500`} />
        ) : (
          <Box width="full">
            <Box
              py={[4, 6, 8]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              opacity={0.5}
            >
              <Figure type="logoHusa" size={[55, 60, 68]} />
            </Box>
          </Box>
        )}
        <Box
          p={2}
          mt={2}
          borderTop="light"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color="dark500" m={0} fontWeight="bold">
            {Name}
          </Text>
          <IconWrapper>
            <IconComponent type="arrowRight" color="primary300" />
          </IconWrapper>
        </Box>
      </StyledCard>
    </Box>
  </Box>
);
