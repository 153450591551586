import { ProductSearchResultsResponse } from 'types'
import { Icon } from 'ui/core'
import { Modal } from './modal/modal'
import { StyledAnchor } from './searchModal.styles'

interface Props {
  loading: boolean
  isSearching: boolean
  setIsSearching: (isSearching: boolean) => void
  query: string
  setQuery: (q: string) => void
  productResults: ProductSearchResultsResponse | null
  productsLoading: boolean
  total: number
  postSearchResult: (productId: string) => void
}

export const SearchModal = ({ isSearching, query, ...props }: Props) => (
  <Modal
    baseId="search-modal"
    ariaLabel="Leitargluggi"
    disclosure={
      <StyledAnchor aria-label="Opna leit">
        <Icon type="search" size="26" color="primary300" />
      </StyledAnchor>
    }
    query={query}
    isSearching={isSearching}
    searchLink={'/leit?q=' + query}
    {...props}
  />
)
