import axios from 'axios'
import { useEffect, useState } from 'react'
import { ProductSearchResultsResponse } from 'types'
import { getStoreAlias } from 'ui/utils'

const SearchBaseUrl = '/webapi/search'
const SearchRouteBaseUrl = `${SearchBaseUrl}/query`

const MIN_LENGTH = 3

export type SearchResultStatus = 'loading' | 'fulfilled' | 'error' | 'passive'

export const useProductSearch = (count = 20, searchOnInit = true) => {
  const [from, setFrom] = useState<number>(0)
  const [value, setValue] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [results, setResults] = useState<ProductSearchResultsResponse | null>(
    null,
  )
  const [status, setStatus] = useState<SearchResultStatus>('passive')

  const search = async () => {

    if (!value || value.length < MIN_LENGTH) {
      setResults(null)
      setStatus('error')
      setErrorMessage('Leitin er of stutt')
      return
    }
    if (errorMessage) setErrorMessage('')
    setStatus('loading')
    const res = await axios.get<ProductSearchResultsResponse>(
      `${SearchRouteBaseUrl}/${value}?from=${from}&count=${count}&storeAlias=${getStoreAlias()}`,
    )

    if (res && res.data && res.data.IsSuccess) {
      setResults(res.data)
      setStatus('fulfilled')
    } else {
      setResults(null)
      setStatus('error')
      setErrorMessage('Ekki tókst að sækja gögn')
    }
  }

  const clear = async () => {
    setStatus('passive')
    setResults(null)
  }

  const postSearchResult = (productId: string) => {
    axios.post<{ success: boolean }>(`${SearchBaseUrl}/PostSearchResult`, {
      query: value,
      productId,
    })
  }

  useEffect(() => {
    search()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, value])

  useEffect(() => {
    if (searchOnInit && value && value.length >= MIN_LENGTH) {
      search()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    search,
    clear,
    results,
    status,
    from,
    setFrom,
    value,
    setValue,
    errorMessage,
    postSearchResult,
  }
}
