import { Anchor, Box, Figure, Icon, Text } from 'ui/core'
import { SearchIconWrapper } from './mobileSearch.styles'
import { ProductSearchResultsResponse } from 'types'
import { SearchQueryResults } from '../../searchResults/searchResults'
import { SearchInput } from 'components/searchInput/searchInput'
import { SearchProductsResults } from '../../searchResults/searchProductsResults'

interface Props {
  query: string
  isSearching: boolean
  setQuery: (q: string) => void
  loading: boolean
  closeModal: () => void
  searchLink: string
  productResults: ProductSearchResultsResponse | null
  postSearchResult: (productId: string) => void
  productsLoading: boolean
  total: number
}
export const MobileSearch = ({
  closeModal,
  isSearching,
  loading,
  query,
  searchLink,
  setQuery,
  productResults,
  productsLoading,
  postSearchResult,
  total,
}: Props) => (
  <Box minHeight="viewportHeight" position="relative" py={6}>
    <Box
      width="full"
      display="flex"
      justifyContent="flex-end"
      pt={4}
      pb={2}
      mr={1}
    >
      <Anchor onClick={closeModal}>
        <Box display="flex" alignItems="center">
          <Icon type="close" color="dark400" size="24" />
        </Box>
      </Anchor>
    </Box>
    <Box
      position="relative"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Box flexGrow={1} display="flex" alignItems="flex-end">
        <SearchInput
          boxSize="large"
          name="search-input-mobile"
          type="search"
          autoComplete="off"
          value={query}
          placeholder="Sláðu inn leitarorð"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              window.location.href = searchLink
            }
          }}
          onChange={(e) => setQuery(e.target.value)}
        />
      </Box>
      <SearchIconWrapper>
        <Figure type="search" color="primary300" size={[20, 25, 30, 40]} />
      </SearchIconWrapper>
    </Box>
    <Box p={2} bg="white">
      {isSearching && query !== '' && query.length < 3 ? (
        <Box width="full" justifyContent="center" textAlign="center">
          <Text variant="h5" color="dark300">
            Leitin er of stutt
          </Text>
        </Box>
      ) : !loading && isSearching && total < 1 && query !== '' ? (
        <Box width="full" justifyContent="center" textAlign="center">
          <Text variant="h5" color="dark500">
            Ekkert fannst fyrir þessi leitarskilyrði
          </Text>
        </Box>
      ) : (
        <>
          {query.length > 3 && (
            <>
              <Box pb={2}>
                <SearchProductsResults
                  postSearchResult={postSearchResult}
                  showResults={10}
                  res={productResults?.Products || []}
                  loading={productsLoading}
                  resultsName="Vörur"
                />
              </Box>
              <Box pt={2}>
                <SearchQueryResults
                  numResToShow={20}
                  loading={productsLoading}
                  res={productResults?.ProductCategories || []}
                  resultsName="Vöruflokkar"
                />
              </Box>
              {(productResults?.Content || []).length > 0 && (
                <Box pt={6}>
                  <SearchQueryResults
                    numResToShow={20}
                    loading={productsLoading}
                    res={productResults?.Content || []}
                    resultsName="Efnissíður"
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
      {(productResults?.Count || 0) > 10 && (
        <Box width="full" pt={6} display="flex" justifyContent="center">
          <Anchor
            variant="standard-underline"
            fontWeight="light"
            iconLeft="search"
            href={searchLink}
          >
            Sjá allar leitarniðurstöður
          </Anchor>
        </Box>
      )}
    </Box>
  </Box>
)
