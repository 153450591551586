import { ThemeProvider } from 'styled-components'
import { getTheme } from '../ui/theme'
import { FC, useEffect, useState } from 'react'
import axios from 'axios'
import { AncestorNode, RentDevice } from 'types/rentDevice'
import { ThemeVariant } from 'types/theme'
import { Container, Box, Text, GridRow } from 'ui/core'
import Loader from 'ui/core/loader/loader'
import { PageHeader } from 'components/pageHeader/pageHeader'
import { RentDeviceBox } from 'components/rentDeviceBox/rentDeviceBox'

interface RentDevicesProps {
  categoryId?: string | null
  categoryName?: string | null
  title?: string
  site: ThemeVariant
  ancestors: AncestorNode[]
}
export const RentDevicesApp: FC<RentDevicesProps> = ({
  categoryId,
  categoryName,
  site,
  ancestors,
}) => {
  const [devices, setDevices] = useState<RentDevice[]>([])
  const [devicesLoading, setDevicesLoading] = useState<boolean>(true)
  const theme = getTheme(site)
  const fetchDevices = () => {
    setDevicesLoading(true)
    axios
      .get(`/webapi/RentDevices/categories/${categoryId}/devices`)
      .then((res) => {
        setDevices(!res?.data ? [] : res.data)
        setDevicesLoading(false)
      })
      .catch((e) => setDevicesLoading(false))
  }

  useEffect(() => {
    fetchDevices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <PageHeader title={categoryName || 'Flokkur'} ancestors={ancestors} />
        <Box py={1} minHeight="viewportHeight">
          {devicesLoading ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Text variant="textIntro" fontWeight="medium" mb={4}>
                Sæki tæki...
              </Text>
              <Loader />
            </Box>
          ) : (
            <GridRow>
              {!devicesLoading &&
                devices &&
                devices.length > 0 &&
                devices.map((device, index) => (
                  <RentDeviceBox key={device.ItemId || index} device={device} />
                ))}
            </GridRow>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  )
}
