import { provideHeaderData } from 'helpers'
import { ThemeProvider } from 'styled-components'
import { HeaderComponent } from 'components'
import { getTheme } from '../ui/theme'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Account } from 'types/account'

export const HeaderApp = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false)
  const [contentMenuOpen, setContentMenuOpen] = useState<boolean>(false)
  const [accounts, setAccounts] = useState<Account[] | null>(null)
  const [accountsLoading, setAccountsLoading] = useState<boolean>(false)
  const header = provideHeaderData()
  const theme = getTheme(header.theme)
  const getAccounts = () => {
    if (header.loggedIn) {
      setAccountsLoading(true)
      axios
        .get(`/webapi/account/accounts`)
        .then((res) => {
          setAccounts(!res?.data ? [] : res.data)
          setAccountsLoading(false)
        })
        .catch((e) => setAccountsLoading(false))
    }
  }
  useEffect(() => {
    getAccounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSetMenuState = (isOpen: boolean) => setMenuOpen(isOpen)

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent
        theme={header.theme}
        userName={
          header.userName.length >= 30
            ? header.userName.slice(0, 28) + '...'
            : header.userName
        }
        isLoggedIn={header.loggedIn}
        accountsLoading={accountsLoading}
        header={header}
        menuOpen={menuOpen}
        contentMenuOpen={contentMenuOpen}
        userMenuOpen={userMenuOpen}
        accounts={accounts}
        setMenuState={handleSetMenuState}
        toggleMenu={() => {
          if (!menuOpen && contentMenuOpen) setContentMenuOpen(false)
          if (!menuOpen && userMenuOpen) setUserMenuOpen(false)
          setMenuOpen(!menuOpen)
        }}
        toggleContentMenu={() => {
          if (!contentMenuOpen && menuOpen) setMenuOpen(false)
          if (!contentMenuOpen && userMenuOpen) setUserMenuOpen(false)
          setContentMenuOpen(!contentMenuOpen)
        }}
        toggleUserMenu={() => {
          if (!userMenuOpen && menuOpen) setMenuOpen(false)
          if (!userMenuOpen && contentMenuOpen) setContentMenuOpen(false)
          setUserMenuOpen(!userMenuOpen)
        }}
      />
    </ThemeProvider>
  )
}
