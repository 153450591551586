import { useEffect, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { Anchor, Box, Hidden, Text } from 'ui/core'
import { DropdownWrapper } from '../headerSearchInput.styles'
import { SearchProductsResults } from '../searchResults/searchProductsResults'
import { useClickAway } from 'react-use'
import { SearchQueryResults } from '../searchResults/searchResults'
import { ProductSearchResultsResponse } from 'types'

export const SearchDropdown = ({
  query,
  productsLoading,
  searching,
  productResults,
  total,
  postSearchResult,
  closeSearch,
}: {
  query: string
  closeSearch: () => void
  searching: boolean
  productResults: ProductSearchResultsResponse | null
  productsLoading: boolean
  total: number
  postSearchResult: (productId: string) => void
}) => {
  const [height, setHeight] = useState<string | number>(0)
  const ref = useRef(null)
  const searchLink = '/leit?q=' + query
  const displayProducts =
    productsLoading ||
    (productResults?.ProductsCount && productResults?.ProductsCount > 0) ||
    false

  useEffect(() => {
    if (!searching && height !== 0) setHeight(0)
    else if (searching && height === 0) setHeight('auto')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searching])

  useClickAway(ref, () => closeSearch())
  return (
    <Hidden below="lg" width="full" position="relative">
      <Box
        ref={ref}
        height="full"
        width="full"
        position={['fixed', 'fixed', 'absolute']}
        right={[0, 0, 0, 'inherit']}
        zIndex="header"
      >
        <AnimateHeight duration={400} height={height} style={{ minWidth: 650 }}>
          <DropdownWrapper
            p={3}
            bg="white"
            width="full"
            style={{ minWidth: 700 }}
          >
            <Box width="full" display="flex" justifyContent="space-between">
              {!productsLoading && total < 1 ? (
                <Box width="full" display="flex" justifyContent="center" pb={3}>
                  <Box width="full" justifyContent="center" textAlign="center">
                    <Text variant="h5" color="dark500">
                      Ekkert fannst fyrir þessi leitarskilyrði
                    </Text>
                  </Box>
                </Box>
              ) : (
                <>
                  {displayProducts && (
                    <Box
                      width={
                        total === (productResults?.ProductsCount || 0)
                          ? 'full'
                          : '6/12'
                      }
                    >
                      <SearchProductsResults
                        postSearchResult={postSearchResult}
                        res={productResults?.Products || []}
                        productCount={productResults?.ProductsCount}
                        loading={productsLoading}
                        resultsName="Vörur"
                      />
                      {!productsLoading &&
                        productResults?.ProductsCount &&
                        productResults?.ProductsCount > 5 && (
                          <Box pt={3} pb={2} px={2}>
                            <Anchor
                              variant="standard-underline"
                              fontWeight="light"
                              iconLeft="search"
                              href={searchLink}
                            >
                              {`Sjá allar (${productResults?.ProductsCount})`}
                            </Anchor>
                          </Box>
                        )}
                    </Box>
                  )}
                  <Box
                    width={
                      !productsLoading && (productResults?.Count || 0) < 1
                        ? 'full'
                        : '5/12'
                    }
                  >
                    <SearchQueryResults
                      resultsName="Vöruflokkar"
                      res={productResults?.ProductCategories || []}
                      loading={productsLoading}
                    />
                    {(productResults?.ContentCount || 0) > 0 && (
                      <Box pt={3}>
                        <SearchQueryResults
                          resultsName="Efnissíður"
                          res={productResults?.Content || []}
                          loading={productsLoading}
                        />
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </DropdownWrapper>
        </AnimateHeight>
      </Box>
    </Hidden>
  )
}
